/** @jsx jsx */
import { jsx, Container, Flex, Box, Text, Message } from 'theme-ui'
import { parse } from 'query-string'
import { pipe, prop } from 'ramda'
import { useLocation } from '@reach/router'
import Layout from '~/components/layout'
import urls from '~/common/urls'
import SignInContainer from '~/containers/sign-in'
import { Button } from '~/components/button'
import { space } from '~/gatsby-plugin-theme-ui'
import HeadingV2 from '~/componentsV2/heading'
import { graphql, useStaticQuery } from 'gatsby'
import { IContractDesignStudioData } from '~/componentsV2/contract-design-studio'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
  HeroSection,
  HeroSectionContent,
  HeroSectionImage,
} from '~/componentsV2/contract-design-studio/styles'
import { H1 } from '~/styled/components/typography'

enum STATE_TYPE {
  PASSWORD_RECOVERED = 'password-recovered',
}

const getMessageByState = (state: string) => {
  switch (state) {
    case STATE_TYPE.PASSWORD_RECOVERED:
      return "We've sent you an email with a link to update your password."
    default:
      return ''
  }
}

const getState = pipe(parse, prop('state'))

type QueryResult = {
  pageData: {
    frontmatter: IContractDesignStudioData
    html: string
  }
}

const SignIn = () => {
  const { search } = useLocation()
  const state = getState(search)

  const query = useStaticQuery<QueryResult>(graphql`
    query {
      pageData: markdownRemark(frontmatter: { templateKey: { eq: "custom-rug-studio" } }) {
        frontmatter {
          overlayColor
          heroSize
          heroBackground {
            childImageSharp {
              gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
      }
    }
  `)
  const heroBackground = getImage(query?.pageData?.frontmatter?.heroBackground)

  return (
    <Layout
      headerVariant="light"
      title="Account"
      description="Account"
      topBanner={false}
      stickyMenu={true}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        gap: [20, 35],
      }}
    >
      <HeroSection
        size={query?.pageData?.frontmatter?.heroSize}
        overlay={true}
        overlayColor={query?.pageData?.frontmatter?.overlayColor}
      >
        {heroBackground && (
          <HeroSectionImage>
            <GatsbyImage image={heroBackground} alt="Account" />
          </HeroSectionImage>
        )}
        <HeroSectionContent>
          <H1 style={{ margin: 0 }}>ACCOUNT</H1>
        </HeroSectionContent>
      </HeroSection>
      <Container
        sx={{
          pt: [25, null, 50],
        }}
      >
        {/* <HeadingV2
          sx={{
            textAlign: ['center', null, 'start'],
            borderBottom: '1px solid #E6E6E6',
            pb: [0, null, '21px'],
            mb: [20, null, '20px'],
            textTransform: 'uppercase',
          }}
        >
          Account
        </HeadingV2> */}
        {state && (
          <Message
            sx={{
              mb: [40],
            }}
          >
            {getMessageByState(state)}
          </Message>
        )}
        <Flex
          sx={{
            flexDirection: ['column', null, null, 'row'],
            pb: [68, null, null, 197],
          }}
        >
          <Box
            as="section"
            sx={{
              flex: 1,
              pr: [0, null, null, 135],
              borderRight: t => ['none', null, null, `1px solid ${t.colors.border}`],
            }}
          >
            <HeadingV2
              level="2"
              sx={{
                fontSize: [2, null, '22px'],
                mb: [20, null, 46],
                textTransform: 'uppercase',
              }}
            >
              Returning Customer
            </HeadingV2>
            <SignInContainer />
            <Button
              as="link"
              to={urls.account.forgotPassword}
              variant="link"
              sxProps={{
                textAlign: 'center',
                width: '100%',
                textDecoration: 'underline',
                color: '#595858',
              }}
              label="FORGOT PASSWORD?"
            />
          </Box>
          <Box as="section" sx={{ flex: 1, pl: [0, null, null, 135], mt: [70, null, 0] }}>
            <HeadingV2
              level="2"
              sx={{
                fontSize: [2, null, '22px'],
                mb: [space.md3, null, 40],
                textTransform: 'uppercase',
                display: ['none', null, 'block'],
              }}
            >
              Create Account
            </HeadingV2>
            <Flex
              sx={{
                flexDirection: 'column',
                mb: [52],
              }}
            >
              <Box
                sx={{
                  mb: [space.md1],
                }}
              >
                <Box>
                  <HeadingV2
                    level="3"
                    sx={{
                      fontSize: [1, null, 3],
                      textTransform: 'uppercase',
                      mb: [space.md],
                      marginBlock: 0,
                      marginBlockEnd: [space.md],
                      color: '#2b2b2b',
                    }}
                  >
                    Set up new Account
                  </HeadingV2>
                </Box>
                <Text as="p" variant="xsmall">
                  Create a username and password to place orders online and view your order history.
                </Text>
              </Box>
              <Box>
                <Button
                  sx={{ mb: [space.l] }}
                  as="link"
                  to={urls.account.signUp}
                  label="Create Account"
                  mobileBlock
                  sxProps={{
                    width: '100%',
                    backgroundColor: ['transparent', null, '#01182B'],
                    border: ['none', null, '1px solid #01182B'],
                    color: ['#595858', null, 'white'],
                    textDecoration: ['underline', null, 'none'],
                  }}
                />
                <Box
                  sx={{
                    mb: [space.md1],
                  }}
                >
                  <Box>
                    <HeadingV2
                      level="3"
                      sx={{
                        fontSize: [1, null, 3],
                        textTransform: 'uppercase',
                        mb: [space.md],
                        color: '#2b2b2b',
                        marginBlock: 0,
                        marginBlockEnd: [space.md],
                      }}
                    >
                      Join Our Trade Program
                    </HeadingV2>
                  </Box>
                </Box>
                <Text
                  as="p"
                  variant="xsmall"
                  sx={{
                    mb: [space.md1],
                  }}
                >
                  Design Community members may register here to be considered for our Trade program
                  and receive exclusive Trade courtesy benefits.
                </Text>
              </Box>
              <Button
                as="link"
                to={urls.pages.tradeProgram}
                label="Trade Registration"
                mobileBlock
                sxProps={{
                  backgroundColor: ['transparent', null, '#01182B'],
                  border: ['none', null, '1px solid #01182B'],
                  color: ['#595858', null, 'white'],
                  textDecoration: ['underline', null, 'none'],
                }}
              />
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}

export default SignIn
