import styled from 'styled-components'

// Styled component named StyledAlert
export const StyledAlert = styled.div`
  font-family: Roboto, Helvetica, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', sans-serif;
  color: #2b2828;
  background: #ebebeb;
  margin: 10px 0;
  border-radius: 0;
  font-size: 16px;
  line-height: 34px;
  letter-spacing: 0.5px;
  height: 42px;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`

// export const StyledAlert = styled.div`
//   background: #d4edda;
//   color: #155724;
//   padding: 20px;
//   margin: 10px 0;
//   border: 1px solid #c3e6cb;
//   border-radius: 5px;
//   text-align: center;
// `
