import styled, { css } from 'styled-components'
import { Paragraph } from '~/styled/components/typography'

interface HeroSectionProps {
  size?: 'small' | 'large'
  overlay?: boolean
  overlayColor?: string
}

export const HeroSectionImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`

export const HeroSectionContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  text-align: center;
  color: white;
  padding: 70px 40px;

  // @hack to fix height 100% on safari ios
  min-height: 468px;

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    // @hack to fix height 100% on safari ios
    min-height: 368px;
    padding: 40px 20px;
  }
`
const sizes = {
  small: 330,
  large: 468,
}

const sizesMobile = {
  small: 330,
  large: 368,
}

export const HeroSection = styled.div<HeroSectionProps>`
  position: relative;
  width: 100%;
  aspect-ratio: 1920 / ${sizes.large};
  min-height: ${sizes.large}px;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #041826b2;
  }

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    min-height: ${sizesMobile.large}px;
  }

  ${({ size }) =>
    size === 'small' &&
    css`
      aspect-ratio: 1920 / ${sizes.small};
      min-height: ${sizes.small}px;

      ${HeroSectionContent} {
        min-height: ${sizes.small}px;
      }
    `}

  ${({ overlay }) =>
    !overlay &&
    css`
      &:after {
        display: none;
      }
    `}

  ${({ overlayColor }) =>
    overlayColor &&
    css`
      &:after {
        background: ${overlayColor};
      }
    `}
`

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  padding: 64px 40px;
  box-sizing: border-box;

  ${({ theme }) => '@media ' + theme.mediaQueries.maxMd} {
    padding: 34px 20px;
  }

  color: #666666;

  h1,
  h2,
  h3 {
    margin-bottom: 20px;
    color: #2b2b2b;
  }

  p {
    line-height: 26px;
  }

  display: flex;
  flex-direction: column;
  gap: 64px;
`

interface HighlightInfoProps {
  $border?: boolean
}

export const HighlightInfo = styled.span<HighlightInfoProps>`
  ${({ $border }) => $border && 'border-bottom: 2px #01182b solid;'}

  a {
    color: #666666;
    text-decoration: none;
    &:hover {
      border-bottom: 2px #01182b solid;
    }
  }

  strong,
  span {
    color: #01182b;
  }
`

export const Divider = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 2px rgba(0, 0, 0, 0.1) solid;
  margin-top: 20px;
`
